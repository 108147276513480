<template>
  <div class="card mb-0">
    <div class="card-header mb-0">
      <div class="d-flex-between">
        <h4 class="mb-0">Áreas</h4>
        <small>Arraste a categoria para organizar</small>
      </div>
    </div>
    <div class="card-body p-0">
      <draggable
        v-model="areas"
        class="list-group list-group-flush cursor-move"
        tag="ul"
        @change="onChangeSort"
      >
        <b-list-group-item
          v-for="(item, index) in areas"
          :key="index"
          tag="li"
          class="pl-0 pr-0"
        >
          <div class="form-row">
            <div class="col-md-1 text-center d-none d-sm-block">
              <i class="bi bi-arrows-move"></i>
            </div>
            <div
              class="col-md-6 col-7"
              :style="`color: ${item.color_blog_home}`"
            >
              {{ item.title }}
            </div>
            <div
              v-b-tooltip.hover.top="
                `Exibir ${item.limit_blog_home} de ${item.posts} vinculados`
              "
              class="col-md-2 col-3 text-center"
            >
              {{ item.limit_blog_home ? item.limit_blog_home : 0 }}/{{
                item.posts
              }}
            </div>
            <div class="col-md-1 col-1 text-center">
              <b-badge
                v-b-tooltip.hover.top="'Exibindo na Home do Blog'"
                variant="success"
                v-if="item.display_blog_home"
              >
                Ativo
              </b-badge>
            </div>
            <div class="col-md-1 col-1 text-center">
              <b-badge
                variant="warning"
                v-b-tooltip.hover.top="
                  `Layout: ${
                    item.layout_blog_home === '1' ? 'Mini Card' : 'Big Card'
                  }`
                "
                v-if="item.display_blog_home"
              >
                {{ item.layout_blog_home === "1" ? "Mini Card" : "Big Card" }}
              </b-badge>
            </div>
            <div class="col-md-1 col-2 text-center">
              <router-link
                :to="{
                  name: 'blog-areas-edit',
                  params: { uuid: item.code },
                }"
              >
                <feather-icon
                  icon="SearchIcon"
                  class="cursor-pointer cursor mx-1"
                  size="16"
                />
              </router-link>
            </div>
          </div>
        </b-list-group-item>
      </draggable>

      <p v-if="updating" class="mb-0te text-success">
        <b-spinner label="Loading..." small class="mr-1" />
        Atualizando...
      </p>
    </div>
    <div class="card-footer">
      <p class="mb-0">
        Ao ativar, a área e seus posts relacionados serão exibidos na
        <strong>home</strong> do <strong>blog</strong>.
      </p>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  VBTooltip,
  BListGroupItem,
  BCardBody,
  BCardText,
  BSpinner,
  BListGroup,
  BBadge,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BListGroupItem,
    BListGroup,
    BCard,
    BCardBody,
    BCardText,
    draggable,
    BSpinner,
    BBadge,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      category: "",
      posts: [],
      areas: [],
      loadingItems: false,
      updating: false,
    };
  },
  created() {
    this.getAreas();
  },
  methods: {
    onChangeSort(event) {
      if (event.moved) {
        this.updating = true;

        this.$store
          .dispatch(`Sort/updateAreasBlog`, { areas: this.areas })
          .then(() => {
            this.notifyDefault("success");
            this.getAreas();
          })
          .catch(() => {
            this.notifyDefault("error", "Oops, algo não saiu como esperado.");
          })
          .finally(() => {
            this.updating = false;
          });
      }
    },
    onTabChange(category) {
      this.category = category;
      this.getPosts();
    },
    async getAreas() {
      this.loadingItems = true;
      this.areas = [];

      this.$store
        .dispatch(`Post/areas`)
        .then((data) => {
          this.areas = data;
          this.category = this.areas[0];
        })
        .finally(() => {
          this.loadingItems = false;
        });
    },
    async getPosts() {
      this.loadingItems = true;
      this.posts = [];

      this.$store
        .dispatch(`Post/areapost`, this.category.code)
        .then((data) => (this.posts = data))
        .finally(() => {
          this.loadingItems = false;
        });
    },
  },
};
</script>
<style scoped>
.custom-control-areas .switch-icon-right,
.custom-control-areas .switch-icon-left {
  line-height: 1.8rem;
}
</style>
